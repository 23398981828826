/*
 * @Descripttion: 
 * @Author: 银河以北
 * @Date: 2024-04-03 19:32:21
 * @LastEditors: 银河以北
 * @LastEditTime: 2024-04-03 19:53:38
 */
export default class Bus {
    constructor() {
        this.callbacks = {};   // 存放事件的名字  
    }
    $on(name, fn) {
        this.callbacks[name] = this.callbacks[name] || [];
        this.callbacks[name].push(fn);
    }
    $emit(name, args) {
        if (this.callbacks[name]) {
            this.callbacks[name].forEach((cb) => cb(args));
        }
    }
}
